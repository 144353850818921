.advice-container {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 101000!important;
}

.advice-icon {
  background-color: rgba(106, 106, 253, 0.1)!important; /* Transparent blue */
  color: #2999DD!important; /* Blue color */
  transition: background-color 0.3s ease!important;
  opacity: 0.7;
  z-index: 101000!important;
}

.advice-icon:hover {
  background-color: rgba(0, 0, 255, 0.2)!important; /* Slightly darker on hover */
  opacity: 1;
}

.help-icon {
  font-size: 50px !important;
}

.advice-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2999DD;
  background-color: rgba(255, 255, 255); 
  box-shadow: 0 0 5px rgba(0, 123, 255); /* Blue shadow */
  z-index: 102000!important;
  opacity: 1!important;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #2999DD;
}

.close-btn:hover {
  color: #0056b3;
}

.advice-popup p {
  font-family: Arial, sans-serif;
  color: #333;
  font-size: 14px;
  margin-top: 0;
}