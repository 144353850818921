.simple__modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
  }
  
  /* Modal content styles */
  .simple__modal-content {
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	text-align: center;
	z-index: 5;
  }

  .simple__modal-btns {
	display:flex ;
	flex-direction: row;
	justify-content: space-between;
  }