.notifications__dropdown_wrapper {
    position: absolute;
    top: 50px;
    right: 100px;
    left: auto;
    bottom: auto;
    z-index: 2000;
    box-shadow: var(--ds-shadow-overlay, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31));
    background-color: #ffffff;
    border-radius: 3px;
    overflow: auto;
    padding: 20px;
    width: 50%;
}

.notifications__close_btn {
    float: right;
    cursor: pointer;
}
.notifications__dropdown_header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notifications__header_controls {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
}

.notifications__list {
    overflow: auto;
}
.notifications__row {
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.notifications__row_author{
    width: 70%;
}

.notifications__row_buttons {
    width: 30%;
}

@keyframes blinking {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@media (max-width: 767px) { 
    .notifications__row {
        font-size: 12px;
    }
    
    .notifications__row_buttons {
        width: 45%;
  
    }
    .notifications__row_buttons_stack {
        display: flex!important;
        flex-direction: column!important;
        gap: 10px;
    }
}


@media (max-width: 767px)  {
.notifications__dropdown_wrapper {
    padding: 10px;
    width: 80%;
    right: 19px;
}
    
}