.lead-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 14px;
    margin-bottom: 16px;
    transition: transform 0.3s ease-in-out;
  }
  
  .lead-card:hover {
    transform: translateY(-4px);
    transition-duration: 5ms;
  }
  
  .new-lead {
    border-left: 4px solid #2196f3;
  }
  
  .expired-lead {
    border-left: 4px solid #f44336;
  }
  
  .approaching-deadline-lead {
    border-left: 4px solid #ffc107;
  }

  .done {
    border-left: 4px solid #3ca712;
  }
  .rejected {
    border-left: 4px solid #999999;
  }
  .disqualified {
    border-left: 4px solid #999999;
  }
  
  .lead-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .lead-name {
    margin: 0;
    color: #000;
    font-size: 15px;
    text-align: start;
    line-height: 15px;
  }
  
  .lead-info {
    display: flex;
    flex-direction: column;
  }

  .duplicate-info {
    color:  #ed6c02;
  }
  
  .lead-info-item {
    margin: 4px 0;
    font-size: 16px;
    color: #555;
    text-align: start;
  }
  .lead-info-comment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }


  .lead-info-item.manager {
    font-size: 13px;
    line-height: 17px;
    color: #2660c0;
  }
  
  .lead-time-date {
    font-size: 14px;
    color: #777;
  }