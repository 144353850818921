@import "../../scss/index.scss";

.contract__payment_table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #b5dbf3;
}

.contract__payment_table>tbody tr td {
    padding: 3px 3px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.contract__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    gap: 5px;
}

// .contract__actions>button {
//     padding: 0 24px;
// }

