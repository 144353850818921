.input {
	&__datepicker {
		display: flex;
		padding: 8px;
		align-items: flex-start;
		margin-top: 5px;
		font-size: 15px;
		border-radius: 4px;
		border: 1px solid #b7b7b7;
	}
	&__label {
		margin-top: 5px;
		font-size: 14px;
	}
}

.text-error {
	color: #fa1919;
	line-height: unset;
	font-size: 14px;
}

@media (max-width: 600px) {
	.create__lead_and_contact_btn,.create__lead_and_contact_btn,.to_contact_btn,.create__contact_btn,.update__lead_btn,.disqualified__lead_btn,.reject__lead_select {
		font-size: 10px!important;
	}
	.create__lead_wrapper {
		padding: 5px 10px 10px!important;
	}
	// .input-container {
	// 	width: 100%!important;
	//   }
	  
	//   .input__datepicker {
	// 	width: 100%; 
	// 	box-sizing: border-box; 
	// 	font-size: 10px
	//   }
}
