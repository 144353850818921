// .input-file {
// 	margin: 0 auto;
// 	padding: 5px;
// 	width: 95%;
// 	height: 40px;
// 	border: 1px solid #c34ab4;
// 	border-radius: 6px;
// 	background: #bc4ac37a;
// 	// background: #4a9cc37a;
// }

.input-file#file-upload-button {
	background: blue;
	border-radius: 12px;
	color: #ffffff;
}

.input__file-button-text {
	background: blue;
	border-radius: 12px;
	color: #ffffff;
}

.input-file {
	position: relative;
	display: inline-block;
}
.input-file-btn {
	position: relative;
	margin: 0 auto;
	display: block;
	width: 175px;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 14px;
	vertical-align: middle;
	color: rgb(255 255 255);
	text-align: center;
	border-radius: 4px;
	// background-color: #419152;
	background-color: #e795d9;
	line-height: 22px;
	height: 40px;
	padding: 10px 20px;
	box-sizing: border-box;
	border: none;
	transition: background-color 0.2s;
}
.input-file-text {
	padding: 0 10px;
	line-height: 30px;
	display: block;
	margin: 0 auto;
}
.input-file input[type="file"] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}

/* Focus */
.input-file input[type="file"]:focus + .input-file-btn {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Hover/active */
.input-file:hover .input-file-btn {
	// background-color: #59be6e;
	background-color: #f091e8;
}
.input-file:active .input-file-btn {
	// background-color: #2e703a;
	background-color: #5f2064;
}

/* Disabled */
.input-file input[type="file"]:disabled + .input-file-btn {
	background-color: #eee;
}
