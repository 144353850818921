.kanban {
	text-align: center;
	overflow: auto;
	// position: relative;
	// pointer-events: auto;
	height: 100vh;
	width: 100%;
}

.kanban__summary {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
	
}

.kanban__summary_today > span, .kanban__summary_total > span {
	color: #f44336;
}
.kanban__summary_today {
	margin-right: 20px;
	border-bottom: 1px solid #707070;
}
.kanban__summary_total {
	margin-right: 20px;
	border-bottom: 1px solid #707070;
}

.kanban__leads_search_block {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 90%;
	margin-top: 15px;
	flex-wrap: wrap;
}

.leads__page_selector {
	font-size: 1rem;
	padding: 0.5rem;
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: #f9f9f9;
	outline: none;
	cursor: pointer;
	margin: 0 0.5rem;
	font-weight: bold;
}
.leads_pagination > button {
	padding: 0px 2px;
	margin-right: 5px;
}

.board__leads {
	margin-top: 10px;
	display: flex;
	gap: 10px;
	overflow: auto;
	// position: relative;
	width: 100%;
}

.board__leads .column {
	// width: 18%;
	background-color: #f2f2f2;
	width: 250px;
}
.board__leads .column ul {
	min-width: 200px;
	min-height: 1000px;
}

.board__leads .column h2 {
	margin: 0px 0px;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	margin-bottom: 10px;
	opacity: 0.9;
}

.leads_sorting_btns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	align-items: center;
}
.leads_sorting_btn_name {
	line-height: 1;
	font-size: 14px;
}
.leads_sorting_btn {
	display: flex;
	flex-direction: row;
	margin: 5px;
	align-items: center;
	justify-content: center;
}
.search_input_kanban_leads {
	margin-right: 30px;
}
.leads_pagination {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

@media (max-width: 768px) {
	.board {
		flex-direction: column;
	}

	.column {
		margin-bottom: 20px;
	}
}
