.support__page_wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}
.support__page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}
.support__page_title {
  font-weight: 600;
  color: #000;
}
.support__page_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.support__page_card {
  width: 95%;
  margin: 10px 10px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Adds a soft shadow */
}
.support__page_card_text {
  padding: 10px;
  border-radius: 3px;
  background-color: #f5f5f5;
  word-break: break-all;
  overflow-wrap: anywhere;
}
.support__page_card_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.support__page_card_new {
  color: #28a745;
  font-weight: 800;
}

.support__page_card_work {
  color: #007bff; 
  font-weight: 800;
}
.support__page_card_completed {
  color: #ffc107;
  font-weight: 800;
}

.support__page_card_closed {
  color: #6c757d;
  font-weight: 800;
}

.support__page_card_denied_execution {
  color: #dc3545;
  font-weight: 800;
}
.support__page_new_btn > Button {
line-height: 1;
}

.support__page_close_task > Button { 
  padding: 0px 10px;
}


@media (max-width: 768px) {
  .support__page_card {
    width: 100%;
  }
  .support__page_card_header, .support__page_card_text, .support__page_card_footer { 
    font-size: 14px;
  }
  .support__page_card_text {
    padding: 5px;

  }
  .support__page_new_btn > Button {
    padding: 0px 10px;
  }
  .support__page_close_task > Button {
    padding: 0px 10px;
  }
}
 


