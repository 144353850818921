@import "../../scss/index.scss";
.location-tabs {
  background-color: #f0f0f0; 
  color: #333; 
  border-color: #ccc;
  padding: 0;
  }

  .location-tabs .Mui-selected {
    background-color: $main-color; 
    color: #f0f0f0!important;
}