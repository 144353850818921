.popup__wrapper {
// 	  position: relative; 
//    overflow-y: auto; 
//     max-height: calc(100% - 64px);
//     // max-width: 600px;
//     width: 90vw;
// 	top: 0;
//     left: 10%;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
	height: 110vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 4;
}



.task__popup {
	position: absolute;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #2d97d7;
	border-radius: 10px;
	top: 10%;
	left: 50%;
	transform: translate(-50%, 0%);
	height: 85vh;
	max-width: 95vw;
	z-index: 4;
	// position: relative; 
	// overflow-y: auto; 

}

 .tasks__popup_main {
	border-bottom: #000000 solid 0.5px;
	padding-bottom: 20px;
 }

.task__popup_support {
	position: fixed;
	padding: 20px 30px;
	background: #ffffff;
	border: 1px solid #2d97d7;
	border-radius: 10px;
	top: 10%;
	left: 50%;
	transform: translate(-50%, 0%);
	width: 60vw;
	z-index: 4;
}

.popup__container_support {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	overflow: auto;
	width: 100%;
	height: auto;
}

.popup__main_support {
	width: 100%;
}

.wrapper__support_buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
}
.task__popup.update {
	top: 2%;
}

.button__popup {
	display: block;
	position: absolute;
	top: 12%;
	right: 5%;
	margin-left: auto;
	margin-right: 0;
	color: #2d97d7;
	background: transparent;
	border: none;
	cursor: pointer;
}



.popup__footer .popup__subtitle {
	border: #afaeae 1px dashed;
	padding: 3px 0;
	cursor: pointer;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5;
}

.popup__container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	text-align: left;
	height: 100%;
	overflow: auto;
}

.popup__container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #2999dd;
}

.popup__container::-webkit-scrollbar {
	width: 5px;
	background-color: #f9f9fd;
}

.popup__container::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background-color: #f9f9fd;
}

.popup {
	&__title {
		font-size: 20px;
		font-weight: 700;
		color: #2999dd;
	}

	&__subtitle {
		margin-top: 30px;
		font-weight: 700;
		font-size: 16px;
		color: #000000;

		&_name {
			margin-left: 10px;
			font-size: 12px;
			font-weight: 400;
			color: #000000;
		}
	}

	&__descr {
		font-weight: 400;
		font-size: 14px;
	}

	&__right {
		margin-top: 15px;
		width: 30%;
		margin-left: 20px;
	}

	&__left {
		width: 70%;
	}
}

.task__file_add {
	border: #afaeae 1px dashed;
	padding: 3px 0;
	margin-top: 20px;

}

.tasks__add_comment {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
}

.tasks__add_comment_actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 10px;
}

.tasks__add_comment_actions > Button {
	font-size: 12px;
	padding: 4px 4px;
}

.tasks__submit_btns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 20px;
}

.wrapper {
	&__comments {
		margin-top: 5px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__buttons {
		margin-left: 45px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__aside {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__avatar {
		margin-top: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
	}
}

.tasks__file_upload {
	margin: 20px 0;
}
.file-upload-container {
	margin: 20px 0;
	padding: 10px;
	border: 2px dashed #ccc;
	border-radius: 8px;
	text-align: center;
	cursor: pointer;
  }
  
  .file-upload-container:hover {
	background-color: #f5f5f5;
  }
  
  .file-chip {
	display: flex;
	flex: 1;
	width: 100%;
	justify-content: space-between;
  }
  
  .file-chip .delete-icon {
	margin-left: 20px!important;
	cursor: pointer;
	color: #ff1744;
  }


@media (max-width: 767px) {
	.task__modal_executor_group {
		width: 100%;
		flex-wrap: wrap;
	}

	.task__modal_role {}

	.task__popup {
		width: 92%;
		padding: 15px 10px 15px 10px;
		height: auto;
	}

	.popup__container {
		flex-direction: column-reverse;
		width: 98%;
		height: auto;
		overflow: hidden;
	}

	.popup__container .popup__left {
		width: 100%;
	}

	.popup__container .popup__right {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		background-color: #e7e7e7;
		border-radius: 2px;
		align-items: flex-start;
		margin-bottom: 15px;
		width: 90%;
	}

	.wrapper__aside .wrapper__avatar .MuiAvatar-root {
		display: none;
	}

	.wrapper__aside {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.wrapper__avatar {
		margin: 0;
	}

	.popup__subtitle {
		margin: 0;
		font-weight: normal;
	}
}



