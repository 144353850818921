.kanban {
	text-align: center;
	overflow: auto;
	// position: relative;
	// pointer-events: auto;
	height: 100vh;
	width: 100%;
}

.kanban__search_block {
	display: flex;
	flex-direction: row;
	width: 65%;
}

.board__tasks {
	margin-top: 10px;
	display: flex;
	gap: 10px;
	overflow: auto;
	width: 100%;
}

.board__tasks .column {
	background-color: #f2f2f2;
	width: 250px;
}

.board__tasks .column ul {
	min-width: 200px;
	min-height: 1000px;
}

.board__tasks .column h2 {
	margin: 0px 0px;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	margin-bottom: 10px;
	opacity: 0.9;
}

.search_input_kanban {
	width: 55%;
	margin-right: 30px;
}

@media (max-width: 768px) {
	.board {
		flex-direction: column;
	}

	.column {
		margin-bottom: 20px;
	}
	.search_input_kanban {
		width: 100%;
	}
}
