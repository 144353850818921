.empty__fields {
  text-align: center;
  margin-top: 15%;
}

.accordionItem {
  margin: 20px;
  color: #282c34;
}

.group__information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.groupName__icon {
  display: flex;
}

.accDetails {
  margin-top: 25px;
  transition-delay: 500ms;
}

.lessonDate {
  font-size: 14px;
  color: rgb(129, 129, 129);
}

.selectBtn {
  font-size: 14px;
}

.textLable {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1;
}

.topic__wrapper {
  display: flex;
  justify-content: space-between;
}

.lesson__main_text {
  margin: 5px;
  margin-bottom: 25%;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.lesson__main_description {
  margin: 5px;
  margin-bottom: 40%;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.dafault_topick {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.sendBtn {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.groupName {
  margin-left: 10px;
}

.upload_span_element {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  color: #fff;
  background-color: #2999dd;
  border-color: #2999dd;
  cursor: pointer;
}
.upload_span_element:hover {
  color: #fff;
  background-color: #2d79c1;
  border-color: #2d79c1;
  text-decoration: none;
}

.preview__box {
}
.preview__box_images {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.preview__box_img_container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 400px;
  border: 1px dashed #979797;
  padding: 0.5rem;
  height: 4vh;
  margin-bottom: 0.2rem;
}

.preview__box_delete_file {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scale(1.4);
}

.preview__box_images > div > img {
  margin-right: 3px;
  width: auto;
  height: auto;
  display: block;
  object-fit: contain;
  width: 30%;
}
.preview__box_images > div > div {
  font-size: 12px;
  line-height: 0.9;
  width: 65%;
  font-size: 16px;
}

.iconSpan {
  width: 25px;
}

.attachments__box {
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.remove_attachment {
  color: rgba(200, 15, 15, 0.7);
  cursor: pointer;
  font-style: italic;
}

/* .MuiBox-root.css-1myys9o {
  position: relative;
  width: 100%;
  height: 90vh;
} */

.navigation_wrapper {
  box-sizing: border-box;
  position: fixed;
  text-align: center;
  bottom: 1%;
  margin: 0 auto !important;
  padding-left: 1%;
  padding-right: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: rgba(102, 204, 255, 0);
}
.upload_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.navigation_controls {
  width: 100%;
  background-color: rgba(102, 204, 255, 0);
}

.send_btns {
}

/* .MuiBottomNavigationAction-root {
  background-color: rgb(235, 126, 126);
} */
/* .css-1mum06e-MuiBottomNavigationAction-label {
  opacity: 1 !important;
} */
.MuiBottomNavigationAction-root.Mui-selected {
  background-color: rgba(41, 162, 221, 0.25);
  border-radius: 5px;
  width: fit-content;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiDataGrid-main > div:nth-child(2) {
  height: "fit-content !important";
}

@media (max-width: 991px) {
  .tableOpenBtn_lesson {
    line-height: 1;
    padding-top: 0;
    padding-bottom: 0;
  }

  .group__information {
    line-height: 1;
  }

  .accordionItem {
    margin: 20px 5px;
  }
  /* .navigation_wrapper {
    width: 80%;
  } */
}

@media (max-width: 768px) {
  /* .navigation_controls {
    height: 90px;
  } */

  button.tableOpenBtn_lesson {
    padding: 20px 0 0;
    height: 30px;
    font-size: 10px;
  }
  .tableOpenBtn_lesson {
    padding: 0;
    height: 30px;
    font-size: 10px;
  }

  .group__information {
    font-size: 14px;
  }
  .pupils__table_time_picker > div > div > input {
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 480px) {
  .groupName__icon {
    width: 80%;
  }
  .groupName {
    width: 140px;
  }
}
