.links-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 85vh;
    background-color: #fff;
    padding: 0 20px;
}


.orders-show {
  margin-top: 20px;
}

.orders-hide {
  display: none;
}

.reports-orders-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.orders-show p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.reports-box {
  display: flex;
  flex-wrap: wrap;
}



.date-range-picker {
  margin: 20px 0;
  width: 45%;
}

.reports-send-info {
  width: 45%;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
}

.reports-send-info p {
  margin: 10px 0;
}

@media (max-width: 600px) {
  .links-page-wrapper {
    padding: 15px;
  }

  .orders-show .MuiButton-contained {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  .reports-send-info {
    padding: 10px;
  }
}