.maintenance-container {
    text-align: center;
    margin-top: 20vh;
  }
  
  .maintenance-heading {
    color: #ff6347;
    font-size: 2.5rem;
  }
  
  .maintenance-message {
    color: #555;
    font-size: 1.2rem;
    margin-top: 20px;
  }