.card__client_header {
  margin-left: 5px;
}

.card__client_history {
  background: #fff;
  ;
  color: #333;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 10px;
  margin: 5px 10px 10px 10px;
  margin-left: 20px;
  padding: 5px 10px 10px 10px;
  line-height: 1.2;
}

.comment_body {}

.comment__body_contract_number {
  font-size: 14px;
  font-weight: 800;
}

.card_contract_actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card_contract_actions>Button,
.card__lead_actions>Button {
  margin: 3px 3px;
  padding: 2px 3px;
  border: #b4e2ff 1px solid;
  font-size: 12px;
}

.card__client_comment {
  font-size: 14px;
}

.card__client_lead {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.card__client_lead_status_new {
  padding-left: 8px;
  color: #09be12;
  font-weight: 800;
}

div.card__client_lead_status_done {
  padding-left: 8px;
  color: #ffc107;
  font-weight: 800;
}

.card__client_lead_status_in_work {
  padding-left: 8px;
  color: #007bff;
  font-weight: 800;
}

.card__client_lead_status_rejected {
  padding-left: 8px;
  color: #dc3545;
  font-weight: 800;
}

.card__client_lead_status_disqualified {
  padding-left: 8px;
  color: #6c757d;
  font-weight: 800;
}

.card__client_contract_status_new {
  padding-left: 8px;
  color: #09be12;
  font-weight: 800;
}

.card__client_contract_status_active {
  padding-left: 8px;
  color: #007bff;
  font-weight: 800;
}

.card__client_contract_status_cancelled {
  padding-left: 8px;
  color: #6c757d;
  font-weight: 800;
}

.edit-mode {
  position: relative;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: anywhere;
}

.non-editable {
  position: relative;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: anywhere;
}

textarea.editable-textarea {
  display: block;
  width: 100%;
  min-height: 50px;
  resize: vertical;
}

span.edit-button {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

span.save-button {
  position: absolute;
  bottom: 0;
  right: 5px;
  cursor: pointer;
}