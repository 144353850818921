.extend__dialog-download-contract {
  margin-top: 20px;
  padding: 15px;
  font-family: Arial, sans-serif;
}

.extend__dialog-download-contract div {
  font-size: 16px;
  color: #444;
}

.extend__dialog-download-contract span {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.extend__dialog-download-contract span:hover {
  color: #0056b3;
  text-decoration: none;
}

.extend__dialog-download-contract .contract-info {
  font-weight: bold;
}
