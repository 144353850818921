@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;900&display=swap");
$main-color: #2999dd;
$secondary-color: #27dda0;
$download-color: #28a745;
$secondary-bg-color: rgba(39, 221, 160, 0.15);
$main-bg-color: rgba(41, 162, 221, 0.1);
$main-active-bg-color: rgba(41, 162, 221, 0.25);
body {
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  background-color: #f8f8f8;
  // color: #282c34;
  color: #42526E;
  // overflow:hidden;
}

body::-webkit-scrollbar {
  width: 0;
}

.table__title {
  margin: 0;
  display: none;
}
a {
  color: #282c34;
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}
.content {
  display: flex;
  margin-left: 280px;
  margin-top: 65px;
  overflow-x: hidden;
  transition: 0.3s;
}

.content.fullsize {
  display: flex;
  margin-left: 20px;
  margin-top: 65px;
  overflow-x: hidden;
  // width: 100vw;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0px 0 25px;
  box-sizing: border-box;
  //max-width: 1320px;
}
.page__title {
  padding: 20px 30px;
  font-weight: bold !important;
  display: none;
}
.content-component__wrapper {
  border-radius: 10px 0 0 10px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
}

@media (max-width: 991px) {
  .content-component__wrapper {
    overflow: auto;
  }
  .header__breadcrumbs {
    display: none;
  }

  .support_btn {
    position: static!important;
  }


  .content {
    display: flex;
    width: 100%;
    margin-left: 0px;
  }

  // .content.fullsize {
  //   margin-left: 35px;
  // }

  .container {
    padding: 0;
    width: 100%;
  }
}


