.class__schedule_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #bbb8b8 solid 1px;
  padding: 5px;
}

.class__schedule_week_btns {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.class__schedule_helps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

}

.schedule {
  width: 100%;
  height: 73vh;
  overflow-y: scroll;
  overflow-x: scroll;
}

.schedule_cpoying_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 150px;
  z-index: 100;
  font-size: 22px;
  font-weight: bold;
}

.schedule__table {
  width: auto;
  min-width: 100%; 
  table-layout: fixed;
  overflow-x: scroll;
}

/* Style the horizontal scrollbar */
.schedule__table::-webkit-scrollbar {
  width: 20px; /* Width of the scrollbar */
  height: 50px; /* Height of the scrollbar */
}

/* Track (background) */
.schedule__table::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle (thumb) */
.schedule__table::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 10px; /* Roundness of the thumb */
}

/* Handle on hover */
.schedule__table::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when mouse hovers over */
}

.schedule__table tbody{
}

.schedule__table tbody td{
  padding: 0;
  min-width: fit-content;
}

.schedule__table tbody tr:hover {
  background-color: #fff;
}
.schedule__table th, .schedule__table td {
    border: 1px solid #ddd;
    padding: 8px;
    margin: 10px;
    text-align: center;
    width: auto;
    background-color: #fff;
    // word-wrap: break-word; 
  }
  .schedule__table tr {
    border: 2px solid #000;
  }

  .schedule__table th {
    background-color: #f2f2f2;
  }

  .schedule__group_label {
    writing-mode: vertical-rl; /* For modern browsers */
    writing-mode: tb-rl; /* For old browsers */
    text-orientation: upright; /* For modern browsers */
    text-orientation: mixed; /* For old browsers */
    transform: rotate(180deg);
    text-align: center;
    vertical-align: top;
    font-size: 22px;
    float: left;
    padding-right: 15px!important;
  }


  .lesson-cell {
    position: relative;
    height: 60px;
    width: 100%;
    border: 1px solid #ddd;
    margin: 3px;
    display: flex;
    transition: background-color 0.3s ease;
  }
  
  .lesson {
    color: rgb(3, 155, 229);
    padding: 5px;
    border-radius: 4px;
    border: 1px solid rgb(3, 155, 229);
    margin: 3px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family:  Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    z-index: 2;
  }

//   .lesson-cell:hover {
//   background-color: rgba(0, 0, 0, 0.1);
// }
  .lesson:hover {
    background-color: #f2f9ff;
  }

  .lesson-number {
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 12px;
    color: #020202;
    z-index: 3;
  }

  .lesson-time {
    position: absolute;
    top: 0px;
    left: -50px;
    font-size: 14px;
    color: #020202;
  }

  .cell-date {
    position: absolute;
    top: 50%;
    right: 30%;
    font-size: 16px;
    color: #a7a4a4;
  }


  @keyframes blink {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: rgb(255, 0, 0);
    }
    100% {
      background-color: transparent;
    }
  }
  
  .blinking {
    animation: blink 0.5s infinite;
  }





  /* Define breakpoints and adjust table height accordingly */
@media (max-height: 800px) {
  .schedule {
    height: 68vh; /* Adjust as needed for visibility on smaller screens */
  }
}

@media (max-height: 500px) {
  .schedule {
    height: 55vh; /* Further adjustment for very small screens */
  }
}
  
  



