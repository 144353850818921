.attendance__report_filters_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.attendance__report_filters {
  display: flex;
  flex-direction: row;
  width: 90%;
}
.attendance__report_btns {
  display: flex;
  flex-direction: column;
  width: 10%;
  justify-content: space-between;
  align-items: center;
}

.attendance__report_student_iformation_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.attendance__report_student_iformation {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 80vh;
}
.workingOff{
  background-color: rgba(179, 179, 179, 0.8);
}
.attendance__top_btn {
  position: fixed!important;
    right: 20px;
    bottom: 110px;
    opacity: 0.7;
    color: #2999DD;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 90;
}


.attendance__down_btn {
  position: fixed!important;
    right: 20px;
    bottom: 60px;
    opacity: 0.7;
    color: #2999DD;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 90;
}

.attendance__report_student_iformation table tbody td {
  padding: 0.3rem;
}
.attendance__report_student_iformation table tbody th {
  padding: 0.3rem;
}

.attendance__report_student_iformation td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.attendance__report_student_iformation table {
  table-layout: auto;
  width: 100%;
  overflow-x: auto;
}

.attendance__report_student_iformation th, 
.attendance__report_student_iformation td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  white-space: normal;      
  word-break: break-word;    
  overflow-wrap: break-word; 
  text-align: left;
}
.attendance__report_student_iformation th {
  white-space: nowrap;
}

// .attendance__report_student_iformation_wrapper {
//   align-items: flex-start;
//   padding-top: 20px; 
// }

@media only screen and (max-width: 600px) {
  .attendance__report_student_iformation_wrapper {
    max-width: none;
    width: 100%;
  }
  .attendance__report_student_iformation table,
  .attendance__report_student_iformation table th,
  .attendance__report_student_iformation table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
  }

  .attendance__report_student_iformation table th,
  .attendance__report_student_iformation table td {
    text-align: left;
  }

  .attendance__report_student_iformation table th {
    font-weight: bold;
  }

}