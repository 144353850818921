@import "../../scss/index.scss";

.contract__templates {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.templates__sector {
    width: 50%;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.templates__form-group {
    margin-bottom: 15px;
}

.templates__form-group label {
    display: block;
    margin-bottom: 5px;
}

.templates__form-group select,
.templates__form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.btn_template {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #2999dd;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.btn_template:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.template-download {
    background-color: $download-color;
    margin-bottom: 10px;
}

.template-upload {
}
.template__upload_btns {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.templates__upload-form {
    margin-top: 20px;
}

.templates__dropzone {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: #fafafa;
    position: relative;
}

.templates__dropzone p {
    margin: 0;
    color: #999;
    pointer-events: none;
}