@import "../../scss/index.scss";


.config-editor {}


.config-editor h2 {
    color: #333;
    font-size: 1.8em;
    font-weight: bold;
    margin: 0 0 20px 0;
}


.category-header-expand {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.configs__category_delete {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}