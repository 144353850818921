.meal-plan-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px; 
}

.meal-plan-table th,
.meal-plan-table td {
  border: 1px solid #ddd;
  padding: 2px; 
  text-align: center;
}

.meal-plan-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.meal-plan-table td input {
  width: 100%;
  padding: 2px;
  border: none; 
  background-color: transparent; 
  text-align: center; 
  font-size: 14px;
  color: inherit; 
  box-sizing: border-box;
}

.meal-plan-table td input:focus {
  outline: none; 
  border-bottom: 1px solid #007bff; 
}

.meal-plan-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.meal-plan-table tr:hover {
  background-color: #f1f1f1;
}

.meal-plan-table th,
.meal-plan-table td {
  vertical-align: middle;
}

.meal-plan-table td input:read-only {
  background-color: transparent;
  color: #555; 
  cursor: not-allowed;
}

.meal-plan-table th {
  background-color: #2999DD;
  color: white;
}
.meal-plan-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .meal-plan-table th,
  .meal-plan-table td {
    padding: 4px; 
  }

  .meal-plan-table td input {
    font-size: 10px; 
  }
}