@import "index";
.sidebar-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 9px 12px 9px 12px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  line-height: 19px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  & svg {
    padding-right: 30px;
    vertical-align: middle;
  }
  &:hover {
    background-color: $main-bg-color;
    color: $main-color;
  }
}
.active-navlink > .sidebar-button {
  background-color: $main-active-bg-color;
  color: $main-color;
}
.active {
  background-color: $main-active-bg-color;
  color: $main-color;
}
