/* word wrap without spaces */
.desktop_journal td {
  /* word-break: break-all; */
}
.mobile_journal td {
  /* word-break: break-all; */
}

.mobile_journal {
  display: none;
}
.desktop_journal {
  display: table-row;
}
.desktop_journal th,
.desktop_journal td {
  display: table-cell;
}

.personal_journal_wrapper {
  margin: 0 30px 20px 30px;
}
.personal_journal {
  border-collapse: collapse;
}
.personal_journal tbody {
  margin-bottom: 20px;
  border-bottom: 50px;
}
.personal_journal td,
.personal_journal th,
.personal_journal tr {
  border: 1px solid #afa9a9;
}
.personal_journal td {
  text-align: left;
}

.empty__fields {
  text-align: center;
  margin-top: 15%;
}

.desktop_journal td:nth-child(1) {
  font-weight: 700;
  word-break: normal;
}

.desktop_journal.adjasent {
  border-bottom: hidden;
}



@media (max-width: 991px) {
  .personal_journal_wrapper {
    margin: 0 0;
  }
  .open_close_btn {
    size: small;
    font-size: 12px !important;
    line-height: normal !important;
    /* min-width: none !important; */
  }
}


.attachments__box {
margin-bottom: 2px;
}
.download_attachment {
  color: #5d46e2;
  cursor: pointer;
  font-style: italic;
}

@media (max-width: 768px) {
  .personal_journal td,
  .personal_journal th,
  .personal_journal tr {
    padding: 0.5rem;
  }
  .desktop_journal {
    display: none;
  }
  .mobile_journal {
    display: table-row;
  }
  .mobile_journal th,
  .mobile_journal td {
    display: table-cell;
  }
}

@media (max-width: 580px) {

}

@media (max-width: 600px) {
  .open_close_btn {
    width: 70%;
  }
}

@media (max-width: 320px) {
  .personal_journal td,
  .personal_journal th,
  .personal_journal tr {
    padding: 0.3rem;
  }
}
