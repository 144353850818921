@import "index";
.sidebar {
  position: fixed;
  // overflow: auto;
  left: 0;
  top: 0;
  width: 267px;
  height: 100vh;
  left: -255px;
  border-right: 2px solid rgb(255, 255, 255);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  background-color: #fff;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  z-index: 3;
  color: #42526E;
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 15px;
    height: 90%;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  &__logo {
    width: 150px;
    margin: 0 auto 50px;
  }
}

.sidebar__wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar:hover {
  border-right: 2px solid $main-color;
}

.sidebar.show {
  left: 0;
  width: 280px;
  height: 100vh;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.sidebar__wrapper_overflow_layer {
  height: fit-content;
}

.sidebar__wrapper_overflow_layer::-webkit-scrollbar {
  width: 5px;
  background-color: #f9f9fd;
}

.sidebar__wrapper_overflow_layer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $main-color;
}

.sidebar__wrapper_overflow_layer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

.sidebar__chevron {
  position: absolute;
  z-index: 3;
  left: 96%;
  top: 15%;
  width: 7%;
  height: 6%;
  text-align: center;
  padding-top: 12%;
  border-radius: 10px;
  border: 2px solid rgba(41, 162, 221, 0.25);
  color: $main-color;
  background-color: #fff;
  transition: 0.5s;
}

.sidebar__chevron:hover {
  background-color: $main-color;
  color: #fff;
  border: 2px solid rgba(41, 162, 221, 0.25);
  cursor: pointer;
}


.sidebar__group {
  margin-bottom: 0.7rem;
}

  .sidebar__group-title {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .sidebar__group-items {
    margin-left: 1rem;
  }
  .sidebar__group-icon {
    font-size: 26px;
    text-align: center;
    display: flex;
    align-items: center;
  }
@media (max-width: 991px) {
}

@keyframes slidein {
  from {
    filter: none;
  }

  to {
    filter: invert(21%) sepia(94%) saturate(6742%) hue-rotate(358deg)
      brightness(103%) contrast(110%);
  }
}
