.header {
  position: absolute;
  top: 0;
  display: flex;
  transition: 0.3s;
  width: calc(100% - 300px);
  margin-left: 305px;
  border-radius: 0 0 0 10px;
  font-size: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  // z-index: 3;
  &__wrapper {
    display: flex;
    //max-width: 1320px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 50px 10px 0px;
  }
  &__menu {
    display: flex;
    justify-content: flex-end;
    //max-width: calc(100% - 400px);
    width: 100%;
  }
  &__item {
    position: relative;
    margin: 0 5px;
    padding: 4px 11px;
    vertical-align: middle;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #efefef;
    }
  }
  &__item-img {
    width: 22px;
    height: 30px;
    vertical-align: middle;
  }
}
.stemlab-logo {
  height: 35px;
  width: 100px;
  transition: 1s;
}
.img__logo {
  width: 100%;
}
.hide {
  display: none!important;
}

.header.fullsize {
  margin-left: 0px;
  width: calc(100vw - 50px);
  margin-left: 50px;
}

.header__breadcrumbs {
  font-size: 16px;
  width: 20%;
  padding: 0 20px 0 0px;
}

.search__block {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.search_input {
  width: 35%;
  margin-right: 20px;
}

.user-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
  &__image {
    width: 28px;
    height: 28px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
  &__name {
    font-size: 16px;
    font-weight: 500;
  }
  &__logout {
    font-size: 14px;
    font-weight: 300;
    color: #747474;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  }
}

.notification-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__create_btns {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.mobile-menu {
  display: none;
}

.mobile-menu_btn {
  font-size: 13px!important;
  padding: 3px 12px!important;
}
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 99%;
  right: 3%;
  width: fit-content;
  background-color: #fff;
  box-shadow: 2px 3px 4px rgba(0,0,0,0.2);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  color: #000;
  z-index: 4;
  font-size: 14px;
}

.user_account {
  color: #5E6C84;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.45455;
}

.user-block__email_mobile {
  font-size: 12px;
}

.menu-list li {
  padding: 10px;
  text-align: start;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.menu-list li:last-child {
  border-bottom: none;
}

.menu-list li:hover {
  background-color: #f0f0f0;
}


@media (max-width: 991px) {
  .content-component__wrapper {
    height: calc(90vh - 15px);
  }

  .header {
    display: flex;
    margin-left: 0px;
    width: 100%;
  }

  .container {
    padding: 0;
    width: 100%;
  }
}


@media (max-width: 600px) {
  .search__block {
  
  }

  .user-block__body {
    display: none;
  }
  
  .header__breadcrumbs {
    display: none;
  }
  .support_btn>button {
    font-size: 10px;
  }
  
  .create_lead_btn>button,.create_task_btn>button {
    display: none;
  }
  .search_input {
    display: none;
  }
  .content.fullsize {
    width: auto;
  }
  
  .header.fullsize {
    width: 100%;
    margin: 0;
  }
  
  .header__wrapper {
    justify-content: flex-end;
  }
  
  .mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  
  }
  .support_btn_desktop {
    display: none!important;
  }

  .header__wrapper {
    padding: 10px 5px 10px 0px;
  } 
  .user-block__name {
    font-size: 12px;
  }
  .MuiAvatar-root.MuiAvatar-circular {
    display: none;
  }
  .wrapper__support_buttons>button {
		font-size: 10px;
		padding: 5px 5px;
	}
}



