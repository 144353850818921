/* google button */

.google-btn {
  margin-top: 15px;
  width: 100%;
  height: 40px;
  background-color: #4285f4;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 38px;
  border-radius: 2px;
  background-color: #fff;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  padding-left: 40px;
  text-align: center;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.2px;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
  cursor: pointer;
}
.google-btn:active {
  background: #1669f2;
}
