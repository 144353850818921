.customers__title {
    margin: 0;
    font-style: oblique;
    padding-bottom: 10px;
}
.customers__info {
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    flex-wrap: nowrap;
    margin-left: 20px;

}

.customers__info_container {
    display: flex;
    flex-direction: column;
    padding: 2px 0;
    width: 100%;
}

.customers__info_item {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;

}

.customers__field_name {
  width: 20%;
  text-align: end;
  color: #000;
  font-weight: 600;
}
.customers__person_data {
    font-style: italic;
    width: 80%;
    padding-left: 5%;
}

.customers__person_data {
    margin: 0 10px;
    width: 22%;
}

.user__data {
    transition: all 0.7s ease;
  }

  .dialog__input {
    width: 45%;
    margin: 5px!important;
  }

  .dialog__input.search_field {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .contracts__info_area {
    min-height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .contracts__container{
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  .contracts__item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .contracts_number{
    width: 50%;
  }
  .contracts_action{
    width: 50%;
    cursor: pointer;
    color: #f08080;
    display: flex;
    justify-content: center;
  }
  
  .contracts__disabled {
  }


  .customers__dialog {
    width: 90%!important;
  }

  .confirm__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }