@import "index";

// thead {
//   position: sticky;
//   top: 0;
//   background: white;
// }
// thead th {
//   position: sticky;
//   top: 0;
//   background: white;
// }
// .pvtRowLabel {
//   position: sticky;
//   left: 0;
//   background: rgb(164, 225, 255);
// }

th::after {
}

.table__title {
	font-size: 22px;
	padding: 20px 30px;
}
.table__wrapper {
	margin-top: 20px;
	min-height: 500px;
	& .gridjs-container {
		padding: 0;
		line-height: 1.15;
	}
	& .gridjs-wrapper,
	.gridjs-wrapper:nth-last-of-type(2) {
		border-radius: 0;
	}
	& > div:last-child {
		line-height: 0;
	}
}
table,
.table {
	width: 100%;
	border-spacing: 0;
	background-color: #fff;
	text-align: center;
	& thead,
	.table__thead {
		background-color: $main-bg-color;
		border-top: 1px solid #d3dae1;
		& tr,
		.table__tr {
			border-bottom: 1px solid #d3dae1;
			& .pvtTotalLabel {
				background-color: transparent;
				border-top: none;
			}
		}
		& td,
		& th,
		& .table__td,
		& .table__th {
			padding: 0.75rem;
		}
		& th,
		.table__th {
			border: none;
			background-color: #e9f5fb;
			line-height: 1;
		}
		& th.gridjs-th-sort:hover {
			background-color: #d1e9f8;
		}
		& th,
		.table__th {
			color: #000;
		}
		& .gridjs-th-content {
			text-align: center;
		}
	}
	& td,
	& .pvtRowLabel,
	& .pvtTotalLabel {
		border: none;
		line-height: 1;
		border-top: 1px solid #dee2e6;
	}
	& thead,
	& tbody {
		border-radius: 10px;
	}
	& .pvtTotal,
	& .pvtGrandTotal,
	& .pvtTotalLabel {
		//background-color: $secondary-bg-color;
		font-weight: bold;
	}
	& tbody {
		& td,
		& th {
			padding: 1rem;
			text-align: center;
		}
		& td,
		& .table__td {
			background-color: transparent;
		}
		& tr,
		& .table__tr {
			&:hover {
				background-color: $secondary-bg-color;
			}
		}
	}
	&__input {
		border: none;
		background-color: transparent;
		font-family: "Nunito Sans", sans-serif;
		font-size: 16px;
		color: #282c34;
		text-align: center;
		&:focus {
			background-color: #fff;
			outline: 1px solid $secondary-color;
		}
	}
}
.table-link {
	text-decoration: underline;
}

//----------- paymentsPage

.location__title {
	margin: 20px 0 20px 0;
}
