.task-card {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 14px;
	margin-bottom: 16px;
	transition: transform 0.3s ease-in-out;
}

.task-card:hover {
	transform: translateY(-4px);
	transition-duration: 5ms;
}

// .new-task {
//   border-left: 4px solid #2196f3;
// }

// .expired-task {
//   border-left: 4px solid #f44336;
// }

// .approaching-deadline-task {
//   border-left: 4px solid #ffc107;
// }

.task-card.in_work {
	border-left: 4px solid #2196f3;
}
.task-card.completed {
	border-left: 4px solid #3ca712;
}
.task-card.denied_execution {
	border-left: 4px solid #f44336;
}
.task-card.closed {
	border-left: 4px solid #999999;
}

.task-details {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.task-name {
	margin: 0;
	font-size: 16px;
	text-align: start;
	line-height: 15px;
	width: 70%;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	color: #2660c0;
	padding-bottom: 10px;
}

.task-info {
	display: flex;
	flex-direction: column;
}

.task-info-item {
	margin: 4px 0;
	font-size: 16px;
	color: #555;
	text-align: start;
}
.task-info-comment {
	// white-space: nowrap;
	// overflow: hidden;
	// text-overflow: ellipsis;
	width: 100%;
	text-align: left;
	font-size: 14px;
	color: #000000;
}

.task-info-item.manager {
	font-size: 13px;
	line-height: 17px;
	color: #2660c0;
}

.task-time-date {
    font-size: 14px;
    color: #777;
}

.task-info-item.expired {
	color: #f44336;
	font-size: 14px;
}
