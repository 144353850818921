.groups__control {
  margin-left: 30px;
}

.main {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 500px;
}

.select__helper {
  margin-top: 40px;
}

.border__header {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.border_choosen_box {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  align-items: center;
}

.border_choosen {
  background-color: lightgray;
  border: 2px solid lightslategray;
  border-radius: 20px;
  margin: 7px 7px;
  padding: 0 3px;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.empty__groups {
  text-align: center;
  margin-top: 10%;
}

.board {
  width: 220px;
  height: fit-content;
  border: 1px solid rgb(242, 241, 241);
  border-radius: 3px;
  padding: 30px 10px 0 10px;
  margin: 20px 10px;
  background-color: rgb(242, 241, 241);
}

.board__title {
  color: #28a3dd;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  text-align: left;
}

.items_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 5px;
  min-height: 120px;
}

.item {
  cursor: pointer;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgb(214, 214, 214);
  padding: 7px 10px 7px 10px;
  margin: 5px 0;
  border-radius: 3px;
  box-shadow: 4px 4px 4px -3px rgba(34, 60, 80, 0.2);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .react-kanban-card--dragging {
  transform: rotate(2deg);
} */

/* ------ confirm ------- */

.confirm__container {
  min-width: 35vw;
}

.pupil__info {
  margin: 15px 0px;
}

.groups__wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.group__box {
  width: 40%;
}
.group__box.middle {
  width: 10%;
}

.group__box.left {
  margin-right: 50px;
}

.group_name {
  color: #28a3dd;
}

// .confirm__groups {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: fit-content;
//   margin: 0 10px;
// }

.confirm__buttons {
}

.reset {
  text-align: center;
  min-height: 500px;
}
